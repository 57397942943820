import {GetListResponse, IResourceComponentsProps, useUpdateMany} from "@pankod/refine-core";
import {
    BooleanField,
    Button,
    DateField,
    FilterDropdown,
    Grid,
    Icons,
    List,
    NumberField,
    Select,
    ShowButton,
    Space,
    Table,
    TagField,
    TextField,
    useTable
} from "@pankod/refine-antd";
import React from "react";
import {AttributionCampaign, CampaignInfo} from "../../interfaces";

export const AttributionCampaignsList: React.FC<IResourceComponentsProps<GetListResponse<AttributionCampaign>>> = ({initialData}) => {
    const {
        tableProps
    } = useTable<CampaignInfo>({
        resource: "attribution-campaign/info"
    });

    const {mutate} = useUpdateMany<AttributionCampaign>();

    const breakpoint = Grid.useBreakpoint();

    return (
        <List>
            <Table {...tableProps} expandable={{
                expandedRowRender: !breakpoint.xs
                    ? expandedRowRender
                    : undefined,
            }}
                   rowKey="cid"
            >
                <Table.Column
                    dataIndex="cid"
                    key="cid"
                    title="CID"
                    render={(value) => <TextField value={value}/>}
                />
                <Table.Column
                    dataIndex="impTarget"
                    key="impTarget"
                    title="Impressions Target"
                    render={(value) => <NumberField value={value}/>}
                />
                <Table.Column
                    dataIndex="bidPrice"
                    key="bidPrice"
                    title="Bid Price"
                    render={(value) => <NumberField value={value}/>}
                />
                <Table.Column
                    dataIndex="interstitial"
                    key="interstitial"
                    title="Instl"
                    render={(value) => <BooleanField value={value}/>}
                />
                <Table.Column
                    dataIndex="active"
                    key="status"
                    title="Active"
                    render={(value) => <TagField value={value ? 'On' : 'Off'} color={value ? "green" : "red"}/>}
                    filterDropdown={(props) => (
                        <FilterDropdown {...props}>
                            <Select
                                allowClear
                                placeholder={'Active'}
                                options={[
                                    {
                                        label: "Yes",
                                        value: "true",
                                    },
                                    {
                                        label: "No",
                                        value: "false",
                                    },
                                ]}
                            />
                        </FilterDropdown>
                    )}
                />
                <Table.Column
                    key="createdAt"
                    dataIndex="createdAt"
                    title={"Created at"}
                    render={(value) => <DateField value={value} format="LLL"/>}
                />
                <Table.Column<AttributionCampaign>
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record) => (
                        <Space>
                            <ShowButton hideText size="small" recordItemId={record.cid}/>
                            <Button icon={record.active ? <Icons.PauseCircleOutlined/> : <Icons.PlayCircleOutlined/>}
                                    size={"small"}
                                    onClick={() => mutate({
                                        resource: "attribution-campaign",
                                        ids: [record.cid],
                                        values: {active: !record.active},
                                        mutationMode: "undoable",
                                    })}/>
                        </Space>
                    )}
                />
            </Table>
        </List>
    );
};

const CampaignBundlesTable: React.FC<{ record: CampaignInfo }> = ({record}) => {

    const {tableProps: postTableProps} = useTable<AttributionCampaign>({
        resource: `attribution-campaign/detailed/${record.cid}`,
        permanentFilter: [
            {
                field: "cid",
                operator: "eq",
                value: record.cid,
            },
        ],
        syncWithLocation: false,
    });

    return (
        <List title={"Bundles"} createButtonProps={undefined} canCreate={false}>
            <Table {...postTableProps} rowKey="appBundle">
                <Table.Column
                    dataIndex="sourceId"
                    key="sourceId"
                    title="Source"
                    render={(value) => <NumberField value={value}/>}
                />
                <Table.Column
                    dataIndex="appBundle"
                    key="appBundle"
                    title="Bundle"
                    render={(value) => <TagField value={value} color={"blue"}/>}
                />
                <Table.Column
                    dataIndex="impCount"
                    key="impCount"
                    title="Impressions Count"
                    render={(value) => <NumberField value={value}/>}
                />
                <Table.Column
                    dataIndex="impTarget"
                    key="impTarget"
                    title="Impressions Target"
                    render={(value) => <NumberField value={value}/>}
                />
                <Table.Column
                    dataIndex="spend"
                    key="spend"
                    title="Spend"
                    render={(value) => <NumberField value={value.toFixed(3)}/>}
                />
                <Table.Column
                    key="active"
                    dataIndex="active"
                    title={"Active"}
                    render={(value) => <BooleanField value={value}/>}
                />
            </Table>
        </List>
    );
};

const expandedRowRender = (record: CampaignInfo) => {
    return <CampaignBundlesTable record={record}/>;
};
