import {IResourceComponentsProps, useShow} from "@pankod/refine-core";
import {BooleanField, MarkdownField, Show, Tag, Typography} from "@pankod/refine-antd";

import React from "react";
import {CampaignInfo} from "../../interfaces";

const {Title, Text} = Typography;

export const AttributionCampaignShow: React.FC<IResourceComponentsProps> = () => {
    const {queryResult} = useShow<CampaignInfo>();
    const {data, isLoading} = queryResult;
    const record = data?.data;

    return (
        <Show isLoading={isLoading}>
            <Title level={5}>CID</Title>
            <Text>{record?.cid}</Text>

            <Title level={5}>Targets</Title>
            <Text>
                {record?.targets.map((t) => <Tag key={t.bundle} color={"blue"}>{t.source} - {t.bundle}</Tag>)}
            </Text>

            <Title level={5}>Impressions target</Title>
            <Text>{record?.impTarget}</Text>

            <Title level={5}>Bid price</Title>
            <Text>{record?.bidPrice}</Text>

            <Title level={5}>Interstitial</Title>
            <Text>
                <BooleanField value={record?.interstitial}/>
            </Text>

            <Title level={5}>Active</Title>
            <Text><Tag color={record?.active ? 'green' : 'red'}>{record?.active ? 'On' : 'Off'}</Tag></Text>

            <Title level={5}>Creative</Title>
            <MarkdownField value={record?.creative}/>
        </Show>
    );
};
