import React from "react";
import {Refine} from "@pankod/refine-core";
import {ErrorComponent, Icons, notificationProvider, ReadyPage,} from "@pankod/refine-antd";
import "@pankod/refine-antd/dist/styles.min.css";
import dataProvider from "@pankod/refine-simple-rest";
import {
    AttributionCampaignCreate,
    AttributionCampaignShow,
    AttributionCampaignsList
} from "./pages/attribution-campaigns";
import {Footer, Header, Layout, OffLayoutArea, Sider, Title,} from "./components/layout";
import axios, {AxiosRequestConfig} from "axios";
import {RefineKbarProvider} from "@pankod/refine-kbar";
import routerProvider from "@pankod/refine-react-router-v6";

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
    (request: AxiosRequestConfig) => {
        request.url = `${request.url?.replace("?", "/?")}`
        if (request.method === "post")
            request.url = `${request.url}/`

        if (request.headers) {
            request.headers[
                "Authorization"
                ] = `Basic ${process.env["REACT_APP_AUTOMATION_GATEWAY_KEY"]}`;
        } else {
            request.headers = {
                Authorization: `Basic ${process.env["REACT_APP_AUTOMATION_GATEWAY_KEY"]}`,
            };
        }

        return request;
    },
);

const App: React.FC = () => {
    return (
        <RefineKbarProvider>
            <Refine
                routerProvider={{
                    ...routerProvider,
                }}
                dataProvider={dataProvider(process.env["REACT_APP_AUTOMATION_GATEWAY_URL"] || "", axiosInstance)}
                Layout={Layout}
                ReadyPage={ReadyPage}
                notificationProvider={notificationProvider}
                catchAll={<ErrorComponent/>}
                resources={[
                    {
                        name: "attribution-campaign",
                        list: AttributionCampaignsList,
                        create: AttributionCampaignCreate,
                        show: AttributionCampaignShow,
                        canDelete: false,
                        icon: <Icons.TagsOutlined/>
                    },
                ]}
                Title={Title}
                Header={Header}
                Sider={Sider}
                Footer={Footer}
                OffLayoutArea={OffLayoutArea}
                options={{mutationMode: "pessimistic", warnWhenUnsavedChanges: true}}
            />
        </RefineKbarProvider>
    );
};

export default App;