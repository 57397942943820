import React from "react";
import {Link,} from "react-router-dom";

import {TitleProps} from "@pankod/refine-core";

export const Title: React.FC<TitleProps> = ({collapsed}) => (
    <Link to="/">
        {collapsed ? (
            <img
                src={"/favicon.ico"}
                alt="Bidmachine"
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "12px 24px",
                    width: "75px",
                }}
            />
        ) : (
            <img
                src={"/bm-logo-w.svg"}
                alt="Refine"
                style={{
                    width: "200px",
                    padding: "12px 24px",
                }}
            />
        )}
    </Link>
);
