import React, {useState} from "react";
import {IResourceComponentsProps} from "@pankod/refine-core";
import {Create, Form, Input, useForm, Radio, Space, Button} from "@pankod/refine-antd";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";

import "react-mde/lib/styles/css/react-mde-all.css";
import {AttributionCampaign} from "../../interfaces";


export const AttributionCampaignCreate: React.FC<IResourceComponentsProps> = () => {
    const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");

    const {formProps, saveButtonProps} = useForm<AttributionCampaign>();

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Form.List name="targets">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(field => (
                                <Space key={field.key} align="baseline">
                                    <Form.Item
                                        {...field}
                                        label="Source"
                                        name={[field.name, 'source']}
                                        rules={[{ required: true, message: 'Source is missing' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        {...field}
                                        label="Bundle"
                                        name={[field.name, 'bundle']}
                                        rules={[{ required: true, message: 'Bundle is missing' }]}
                                    >
                                        <Input />
                                    </Form.Item>

                                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                                </Space>
                            ))}

                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    Add source - bundle
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>

                <Form.Item
                    label="Impressions target"
                    name="impTarget"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input type={"number"}/>
                </Form.Item>

                <Form.Item
                    label="Bid price"
                    name="bidPrice"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input type={"number"}/>
                </Form.Item>

                <Form.Item
                    label={"Interstitial"}
                    name="interstitial"
                >
                    <Radio.Group>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>
                            No
                        </Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    label="Creative"
                    name="creative"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <ReactMde
                        selectedTab={selectedTab}
                        onTabChange={setSelectedTab}
                        generateMarkdownPreview={(markdown) =>
                            Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                        }
                    />
                </Form.Item>

            </Form>
        </Create>
    );
};
